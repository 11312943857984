<template>
  <div class="container-title">
    <div class="section-back">
      <router-link to="/" class="backButton">
        <img src="../../assets/BackArrow.svg" alt="back-arrow" />
        Back
      </router-link>
    </div>
    <div class="section-title">
      <h1 class="title-details">
        {{ title }}
        <div
          href="#"
          v-show="isDemo"
          class="popover-title"
        >
          <Bpopover2 :info="info" idPopover="2PB" id="2pbC" />
        </div>
      </h1>
    </div>
  </div>
</template>

<script>
import Bpopover2 from "../B-popover.vue";
export default {
  components: {
    Bpopover2,
  },
  name: "HeaderDetails",
  props: {
    title: {
      type: String,
      default: "",
    },
    isDemo: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      info: `This demo project, presents beneficiaries in employment, 
              with the types of employment status in the 1st drop down, 
              showcased across the programme’s funds or charities in the 
              2nd drop down and matched with certain demographics in the 
              3rd drop down.`,
    }
  },
};
</script>

<style scoped>
.container-title {
  display: flex;
  flex-direction: column;
  padding: 0 5rem 1.5rem 2rem;
}
.section-back {
  display: flex;
  padding: 10px 0 25px;
  justify-content: flex-start;
}
.title-details {
  text-align: left;
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 3.4rem;
  color: var(--color-purple);
  font-weight: 300;
  align-items: center;
}
.popover-title {
  display: flex;
}
.backButton {
  color: #585858;
  font-size: 1.2rem;
  padding: 5px 8px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.2s;
}
.backButton:hover {
  color: #000000;
  background: #dddddd;
}
.backButton:active {
  background: #c9c9c9;
}
</style>
