function selectedDemographicsWatch() {
  if (
    this.selectedStatus == null &&
    this.selectedFund === null &&
    this.selectedDemo !== null &&
    this.selectedDemo !== 'Select option'
  ) {
    this.homePivot = 3;
  }

  if (this.selectedFund === 'Show all') {
    this.selectedFund = null;
  }

  // Status -> Demo -> Demo (Select option)
  if (
    this.selectedStatus !== 'Show all' &&
    this.selectedStatus !== null &&
    (this.selectedFund === null || this.selectedFund === 'Show all') &&
    (this.selectedDemo === 'Select option' || this.selectedDemo === null) &&
    this.homePivot === 1
  ) {
    this.homePivot = 1;
    this.selectedDemo = null;
    this.selectedFund = null;
    this.viewMode = 'Status';
    this.uncheckAllCheckboxes();

    this.viewMode = 'Status';
  }

  if (
    (this.selectedStatus === 'Show all' || this.selectedStatus === null) &&
    (this.selectedFund === 'Show all' || this.selectedFund === null) &&
    (this.selectedDemo === 'Select option' || this.selectedDemo === null)
  ) {
    // this.selectedDemo = null;
    this.homePivot = 0;
    this.viewMode = 'All';
    this.uncheckAllCheckboxes();
  }

  // status -> fund -> demo -> select option
  if (
    this.selectedStatus !== null &&
    this.selectedStatus !== 'Show all' &&
    this.selectedFund !== null &&
    this.selectedDemo === 'Select option'
  ) {
    this.selectedDemo = null;
    this.homePivot = 1;
    this.viewMode = 'Fund';
    this.uncheckAllCheckboxes();
  }

  // case: fund -> select option
  if (
    this.selectedStatus === null &&
    this.selectedStatus !== 'Show all' &&
    this.selectedFund !== null &&
    this.selectedFund !== 'Show all' &&
    this.selectedDemo === 'Select option'
  ) {
    this.selectedDemo = null;
    this.viewMode = 'Fund';
    this.uncheckAllCheckboxes();
  }

  if (
    this.selectedStatus !== null &&
    this.selectedStatus !== 'Show all' &&
    this.selectedDemo !== null &&
    this.selectedDemo !== 'Select option' &&
    // Status -> Fund(show all) -> Demo
    (this.homePivot === 1 || !this.homePivot) &&
    (this.selectedFund === null || this.selectedFund === 'Show all')
  ) {
    this.viewMode = 'Demo';
    this.uncheckAllCheckboxes();
  }
  // caso 2.3.10
  // Fund -> Demo (select option)
  if (
    (this.selectedStatus == null || this.selectedStatus == 'Show all') &&
    this.selectedFund !== null &&
    this.selectedDemo === 'Select option' &&
    this.homePivot == 2
  ) {
    this.selectedDemo = null;
    this.viewMode = 'Fund';
    this.uncheckAllCheckboxes();
  }
  if (
    this.selectedStatus != null &&
    this.selectedFund != null &&
    (this.homePivot == 1 || this.homePivot == 2 || this.homePivot == 3) &&
    this.selectedDemo != null &&
    this.selectedDemo != 'Select option'
  ) {
    this.viewMode = 'Demo';
    this.uncheckAllCheckboxes();
  }
  if (
    (this.selectedStatus == null || this.selectedStatus == 'Show all') &&
    this.selectedFund != null &&
    this.selectedDemo != null &&
    this.selectedDemo != 'Select option'
  ) {
    // Fund -> demo
    this.viewMode = 'Demo';
    this.uncheckAllCheckboxes();
  } else if (
    // Case: Only demographic
    (this.selectedFund === null || this.selectedFund === 'Show all') &&
    this.selectedStatus === null &&
    this.homePivot === 3 &&
    this.selectedDemo !== 'Select option' &&
    this.selectedDemo !== null
  ) {
    this.viewMode = 'Demo';
    this.uncheckAllCheckboxes();
  }
}

export default selectedDemographicsWatch;
