<template>
  <div id="popoverAlice1">
    <div class="my-3 mb-0 mt-2">
      <div :id="idPopover" class="d-flex justify-content-center d-flex align-items-center popover-container">
        <b-icon
          v-show="!iconoType"
          class="h3 mb-2 color-orange"
          icon="info-circle-fill"
        ></b-icon>
        <b-icon
          v-show="iconoType"
          icon="x-circle-fill"
          class="h3 mb-2 color-orange"
        ></b-icon>
      </div>
    </div>

    <b-popover
      :target="idPopover"
      triggers="hover"
      :show.sync="popoverShow"
      placement="right"
      container="popoverAlice"
      ref="popover"
      @show="onShow"
      @hidden="onHidden"
    >
      <div class="p-2">
        {{ info }}
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "popoverAlice1",
  props: {
    info: {
      type: String,
    },
    idPopover: {
      type: String,
    },
  },
  data() {
    return {
      popoverShow: false,
      iconoType: false,
    };
  },
  methods: {
    onClose() {
      this.popoverShow = false;
    },
    onShow() {
      this.iconoType = true;
    },
    onHidden() {
      this.iconoType = false;
    },
  },
};
</script>

<style scoped>
.popover-container {
  width: 33px; 
  height: 33px
}

.color-orange {
  color: #f69855;
}
</style>