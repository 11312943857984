import { api } from '../../../api/stagingApi';

function selectedStatusWatch() {
  if (
    this.selectedStatus !== null &&
    (this.selectedFund === null || this.selectedFund === 'Show all') &&
    this.selectedDemo == null
  ) {
    this.homePivot = 1;
  }

  if (
    this.selectedStatus != null &&
    this.selectedStatus !== 'Show all' &&
    this.selectedFund != null &&
    (this.homePivot == 1 || this.homePivot == 2 || this.homePivot == 3) &&
    this.selectedDemo != null &&
    this.selectedDemo != 'Select option'
  ) {
    this.viewMode = 'Demo';
    this.uncheckAllCheckboxes();
  }

  if (
    (this.selectedFund === 'Show all' || this.selectedFund === null) &&
    this.selectedDemo !== 'Select option' &&
    this.selectedDemo !== null &&
    (this.selectedStatus === null || this.selectedStatus === 'Show all')
  ) {
    this.viewMode = 'Demo';
    this.selectedFund = null;
    this.selectedStatus = null;
    this.uncheckAllCheckboxes();
    return;
  }
  //caso 2.10
  if (
    (this.selectedStatus === 'Show all' &&
      this.selectedFund != null &&
      this.selectedDemo === null) ||
    this.selectedDemo === 'Select option'
  ) {
    // fund -> status show all
    this.viewMode = 'Fund';
    this.selectedStatus = null;
    this.selectedDemo = null;
    this.homePivot = 2;
    this.uncheckAllCheckboxes();
  }

  if (
    this.selectedStatus !== null &&
    this.selectedFund !== null &&
    this.selectedFund !== 'Show all' &&
    (this.homePivot == 1 || this.homePivot == 2 || this.homePivot == 3)
  ) {
    // case: fund -> status
    if (
      this.selectedFund &&
      this.selectedFund !== 'Show all' &&
      this.selectedStatus &&
      this.selectedStatus !== 'Show all' &&
      this.selectedDemo === null
    ) {
      this.homePivot = 1;
      this.selectedDemo = null;
      this.viewMode = 'Fund';
      this.uncheckAllCheckboxes();
      return;
    }

    // Case: Status -> Fund -> Demo -> Show all status
    if (
      (this.selectedFund !== null || this.selectedFund !== 'Show all') &&
      (this.selectedDemo !== null || this.selectedDemo !== 'Select option') &&
      (this.selectedStatus === 'Show all' || this.selectedStatus === null)
    ) {
      this.selectedStatus = null;
      this.viewMode = 'Demo';
      this.homePivot = 3;
      this.uncheckAllCheckboxes();
    }
  } else if (
    this.selectedDemo !== null &&
    this.selectedStatus !== null &&
    (this.selectedFund === null || this.selectedFund === 'Show all') &&
    (this.homePivot === 3 || this.homePivot === 1)
  ) {
    // NEW SERVICE
    this.homePivot = 1;
    this.viewMode = 'Demo';
    this.uncheckAllCheckboxes();

    // END NEW SERVICE
  } else if (
    this.selectedStatus !== 'Show all' &&
    this.selectedStatus !== null &&
    (this.selectedFund === null || this.selectedFund === 'Show all') &&
    this.homePivot == 1
  ) {
    // Case: Status only
    this.homePivot = 1;
    this.selectedFund = null;
    this.selectedDemo = null;

    if (this.selectedStatus !== 'Show all') {
      this.viewMode = 'Status';
      this.uncheckAllCheckboxes();
    }

    // this.viewMode = "Status";
  } else if (
    this.homePivot == 2 &&
    this.selectedStatus != null &&
    this.selectedDemo == null &&
    this.selectedFund != null
  ) {
    this.preloader = true;
    api
      .get(
        `/files/getDashboardBySelections?idImpact=${
          this.impactId
        }&status=${encodeURIComponent(this.selectedStatus)}&fund=&year=${
          this.selectedYear
        }&demo=`
      )
      .then((response) => {
        this.preloader = false;
        const data = response.data.data.data;
        this.groups = data.groups;
        this.tableDataCalculos = data.data;
        this.viewMode = 'Fund';
        this.uncheckAllCheckboxes();
        this.selectedDemo = null;
      });
  }

  if (
    this.selectedDemo !== null &&
    (this.selectedFund === null || this.selectedFund === 'Show all') &&
    (this.homePivot === 3 || this.homePivot === 1)
  ) {
    this.homePivot = 1;
    this.viewMode = 'Demo';
    this.uncheckAllCheckboxes();
  }

  // reinicio de select
  if (
    this.selectedStatus == 'Show all' &&
    (this.selectedFund === null || this.selectedFund === 'Show all') &&
    this.homePivot == 1 &&
    this.viewMode == 'Status'
  ) {
    this.viewMode = 'All';
    this.selectedDemo = null;
    this.selectedFund = null;
    this.uncheckAllCheckboxes();
  }
}

export default selectedStatusWatch;
