<template>
    <div>
        <div
            class="summary-wrapper"
            v-for="status in summaryBoxData"
            v-bind:key="status.vForId"
            :value="status.vForId"
        >
            <div class="text-container" style="align-items: center;">
                <input
                    :disabled="status.totalTenants == 0"
                    type="checkbox"
                    :class="[status.cssId]"
                    :id="status.cssId"
                    :key="status.vForId"
                    :value="status.vForId"
                    @click="$emit('check', status.vForId)"
                    v-model="checkedItems"
                    style="display:none;"
                />
                <label v-bind:class="status.cssId" v-bind:for="status.cssId">
                    <div
                        v-bind:class="[status.cssId, status.totalTenants == 0 ? 'checkbox-disabled' : null]"
                        v-bind:for="status.cssId"
                    >
                    <span
                        v-bind:class="status.cssId"
                        v-bind:for="status.cssId"
                        class="v-text"
                        >V</span
                    >
                    </div>
                </label>
                <div
                    class="summary-text"
                    v-bind:class="status.cssId"
                    v-bind:for="status.cssId"
                >
                    <h2
                        v-bind:class="[status.cssId, status.totalTenants == 0 ? 'checkbox-disabled' : null]"
                        v-bind:for="status.cssId"
                        style="font-size: 2rem"
                    >
                        {{ status.vForId }}
                    </h2>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            status: {
                type: String
            },
            summaryBoxData: {
                type: Array
            },
            checkedItems: {
                type: Array
            }
        }
    }
</script>

<style scoped>
.checkbox-disabled {
    opacity: 0.4;
    cursor: default !important;
}
.summary-text {
  color: #686868;
  width: 100%;
}
.summary-text h1 {
  margin: 0 0 0 0;
}

.v-text {
    color: #ffffff;
    margin: 2px 2px 2px 5px;
    width: 10px;
    height: 10px;
}

</style>