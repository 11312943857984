<template>
    <div>
        <div class="summary-wrapper" v-for="status in summaryBoxData" v-bind:key="status.vForId" :value="status.vForId">
            <div class="text-container">
                <input type="checkbox" v-bind:class="status.cssId" v-bind:id="status.cssId" v-bind:key="status.vForId"
                    :value="status.vForId" @click="$emit('check', status.vForId)" v-model="checkedItems"
                    style="display:none;" />
                <label v-bind:class="status.cssId" v-bind:for="status.cssId" style="margin-top: 20px">
                    <div v-bind:class="status.cssId" v-bind:for="status.cssId">
                        <span v-bind:class="status.cssId" v-bind:for="status.cssId" class="v-text">V</span>
                    </div>
                </label>
                <div class="summary-text" v-bind:class="status.cssId" v-bind:for="status.cssId">
                    <div v-bind:class="status.cssId" v-bind:for="status.cssId" class="box-checkbox-info">
                        <div class="bar-container">
                            <h1 class="total-tenants-text" v-bind:class="status.cssId" v-bind:for="status.cssId">
                                {{ status.totalTenants }}
                            </h1>
                            <h2 class="title-opt" v-bind:class="status.cssId" v-bind:for="status.cssId">
                                {{
                                    (formatStatusName(status.name).includes("Unemployed") ||
                                        formatStatusName(status.name).includes("in"))
                                    ? "Tenants"
                                    : "Tenants"
                                }}
                            </h2>
                        </div>
                    </div>
                    <div v-bind:class="status.cssId" v-bind:for="status.cssId" class="subtitle-opt-container">
                        <h2 class="checkbox-subtitle">
                            {{ formatNameInLineChart(status.name) }}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatNameInLineChart, formatStatusName } from './helpers/checkboxTitleFormats';

export default {
    props: {
        status: {
            type: String
        },
        summaryBoxData: {
            type: Array
        },
        checkedItems: {
            type: Array
        },
    },
    methods: {
        formatStatusName: (statusName) => formatStatusName(statusName),
        formatNameInLineChart: (statusName) => formatNameInLineChart(statusName)
    },
}
</script>

<style scoped>
.summary-text {
    color: #686868;
    width: 100%;
}

.summary-text h1 {
    margin: 0 0 0 0;
}

.bar-container {
    margin-top: -20px;
}

.total-tenants-text {
    display: inline;
    color: '#D8D8D8';
    font-family: Helvetica;
    font-size: 2rem;
    font-weight: 500;
}

.title-opt {
    display: inline;
    color: '#D8D8D8';
    font-family: 'Source Sans Pro';
    font-size: 2rem;
}

.checkbox-subtitle {
    font-family: 'Source Sans Pro';
    font-size: 1.4rem;
    font-weight: 300;
    display: flex !important;
    align-items: center;
    margin: 0;
    color: '#D8D8D8';
}

.v-text {
    color: #ffffff;
    margin: 2px 2px 2px 5px;
    width: 10px;
    height: 10px;
}

.box-checkbox-info {
    border: none;
    color: '#D8D8D8' !important;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
}

.subtitle-opt-container {
    text-align: left;
    border: none;
}
</style>