<template>
    <section :gutter="12" class="chart-title-area">
        <row class="chart-title">
            <column :xs="7">
            <h2 class="projectdetails-sub-title">
                {{ statusLabel }}
                {{ selectedStatus == null ? "- All" : "- " + decodeURIComponent(selectedStatus).trim() }}
                {{ selectedFund != null ? ", " : "" }}
                {{ selectedFund == null ? "" : decodeURIComponent(selectedFund).trim() }}
                {{ selectedDemo != null ? ", " : "" }}
                {{ selectedDemo == null ? "" : decodeURIComponent(selectedDemo).trim() }}
            </h2>

            <div
                id="projectdetails"
                v-if="displayDataUpTo"
                class="projectdetails-sub-title-data projectdetailsSelectData">
                <div class=" projectdetails-sub-title-data-item">
                    Showing data from:
                </div>
                <div
                    class="projectdetails-sub-title-data-item">
                    <v-select
                        id="select-dates-combo"
                        v-model="localEselectedYear" 
                        @input="updateSelectedYear"
                        :options="displayDataUpTo"
                        label="year"
                        class="select-year"
                        placeholder="Show all"
                        :searchable="false"
                        :reduce="(op) => op.year"
                        :disabled="dashboardNeedsPivotX"
                    >
                        <template v-slot:selected-option="option">
                            {{ option.dateRange }}
                        </template>

                        <template slot="option" slot-scope="option">
                            {{ option.dateRange }}
                        </template>
                    </v-select>
                </div>
            
            </div>
            </column>
            <!-- <column class="chart-summary" :xs="5"> -->
            <column class="chart-summary">
            <div class="total-tenants">
                <span
                ><h1>{{ totalTenants }}</h1>
                <h2>{{ unitMeasurement || 'tenants' }}</h2></span
                >
                <h3 class="font-source-sans">
                {{
                    selectedStatus != null
                    ? selectedStatus
                        .replace("N%3FA", "N.F")
                        .replace("e%3Fg%3F ", "e.g.")
                            .replace("?", " ")
                    : selectedStatus || "status"
                }}
                -
                {{
                    selectedFund != null
                    ? selectedFund
                        .replace("e%3Fg%3F ", "e.g.")
                        .replace("N%3FA", "N.F")
                        .replace("?", " ")
                    : selectedFund || "all"
                }}
                </h3>
            </div>
            <div class="difference-people-years">
                <h1 
                    v-if="totalDifferenceTenantsByYears !== null"
                    class="title-h1">
                        {{  diffPeopleYears }}
                    <span>  {{ numberDifferenceUnitMeasurement || 'people' }}</span>
                </h1>
                <h1 
                    v-else
                    class="title-h1">
                    -
                </h1>
                <h3 class="subtitle-h3 font-source-sans">
                {{ growthRatetext || "last 12 months" }}
                </h3>
            </div>
            <div class="growth-rate">
                <h1 class="title-h1">{{ growthRate }}</h1>
                <h3 class="subtitle-h3 font-source-sans">
                {{ dropdownTimePeriodPercentageDifference || "last 12 months" }}
                </h3>
            </div>
            </column>
        </row>
    </section>
</template>

<script>
    export default {
        props: {
            statusLabel: {
                type: String
            },
            totalTenants: {
                type: Number
            },
            totalDifferenceTenantsByYears: {
                type: Number,
            },
            unitMeasurement: {
                type: String
            },
            status: {
                type: String
            },
            displayDataUpTo: {
                type: Array
            },
            selectedStatus: {
                type: String
            },
            selectedFund: {
                type: String
            },
            selectedDemo: {
                type: String
            },
            growthRate: {
                type: String
            },
            growthRatetext: {
                type: String
            },
            numberDifferenceUnitMeasurement: {
                type: String,
            },
            dropdownTimePeriodPercentageDifference: {
                type: String 
            },
            EselectedYear: {
                type: String,
                default: "",
                readonly: true,
            },
            dashboardNeedsPivotX: {
                type: Boolean
            },
        },
        data(){
            return {
                localEselectedYear: this.EselectedYear,
            }
        },
        methods: {
            updateSelectedYear() {
                this.$emit("check", this.localEselectedYear);
            },
        },
        computed: {
            diffPeopleYears() {
                return this.totalDifferenceTenantsByYears > 0
                    ? '+' + this.totalDifferenceTenantsByYears
                    : this.totalDifferenceTenantsByYears;
            },
        },
        watch: {
            EselectedYear() {
                this.localEselectedYear = this.EselectedYear
            }
        }
    }
</script>

<style src="@/assets/styles/components/chart/PeopleEmployment.css"></style>