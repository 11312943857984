function selectedFundsWatch() {
  // Select (show all) -> fund (show all) -> another fund
  if (
    (this.selectedStatus == null || this.selectedStatus == 'Show all') &&
    this.selectedFund !== null &&
    this.selectedDemo != null
  ) {
    this.homePivot = 3;
    this.viewMode = 'Demo';
    this.uncheckAllCheckboxes();
  }

  if (
    this.selectedStatus == null &&
    this.selectedFund !== null &&
    this.selectedDemo == null
  ) {
    this.homePivot = 2;
  }

  // Show all funds when Demo is selected
  if (
    this.selectedDemo !== null &&
    this.selectedDemo !== 'Select option' &&
    (this.selectedStatus === 'Show all' || this.selectedStatus === null) &&
    (this.selectedFund === 'Show all' || this.selectedFund === null)
  ) {
    this.homePivot = 3;
  }

  // Demo -> Show all funds -> Another fund
  if (
    this.selectedDemo !== null &&
    this.selectedDemo !== 'Select option' &&
    this.selectedStatus !== null &&
    this.selectedStatus !== 'Show all' &&
    (this.selectedFund === 'Show all' || this.selectedFund === null)
  ) {
    this.selectedFund = null;
    this.homePivot = 1;
    this.viewMode = 'Demo';
    this.uncheckAllCheckboxes();
  }

  // Status (show all or null) and Demo (Select option or null) -> Show all funds
  if (
    (this.selectedStatus === null || this.selectedStatus === 'Show all') &&
    (this.selectedDemo === null || this.selectedStatus === 'Select option') &&
    (this.selectedFund === null || this.selectedFund === 'Show all')
  ) {
    this.selectedStatus = null;
    this.selectedDemo = null;
    this.viewMode = 'All';
    this.homePivot = 0;
    this.uncheckAllCheckboxes();
  }
  if (
    (this.homePivot === 3 || this.homePivot === 2) &&
    this.selectedFund !== null
  ) {
    if (
      (this.selectedFund === 'Show all' || this.selectedFund === null) &&
      (this.selectedStatus === null || this.selectedStatus === 'Show all')
    ) {
      // Demo -> show all (funds)
      this.selectedFund = null;
      this.selectedStatus = null;
      this.homePivot = 3;
      this.viewMode = 'Demo';
    }

    if (
      ((this.selectedFund !== 'Show all' && this.selectedFund !== null) ||
        this.selectedFund !== null) &&
      this.selectedDemo !== null
    ) {
      this.uncheckAllCheckboxes();
    }
  }

  // Status -> Demo -> Fund
  if (
    this.selectedStatus != null &&
    this.selectedFund != null &&
    this.homePivot == 1 &&
    this.viewMode === 'Demo' &&
    this.selectedDemo != null &&
    this.selectedDemo != 'Select option' &&
    this.selectedFund !== 'Show all'
  ) {
    this.viewMode = 'Demo';
    if (this.selectedStatus !== 'Show all') {
      this.uncheckAllCheckboxes();
    }
  }

  if (
    this.homePivot === 1 &&
    this.selectedFund !== null &&
    this.selectedFund !== 'Show all' &&
    (this.selectedDemo === null || this.selectedDemo === 'Select option') &&
    this.selectedStatus !== null &&
    this.selectedStatus !== 'Show all'
  ) {
    // status -> fund (all opts, except 'show all')
    this.viewMode = 'Fund';
    this.uncheckAllCheckboxes();

    // Case Fund or Show all status -> Fund
  } else if (
    this.selectedFund !== null &&
    this.selectedFund !== 'Show all' &&
    (this.homePivot === 2 || this.homePivot === 1) &&
    (this.selectedStatus === null || this.selectedStatus === 'Show all') &&
    (this.selectedDemo === null || this.selectedDemo === 'Select option')
  ) {
    this.viewMode = 'Fund';
    this.selectedStatus = null;
    this.selectedDemo = null;
    // Only funds
    this.uncheckAllCheckboxes();
  }

  if (
    (this.selectedFund === null || this.selectedFund === 'Show all') &&
    (this.selectedDemo === null || this.selectedDemo === 'Select option') &&
    this.selectedStatus !== null &&
    this.selectedStatus !== 'Show all'
  ) {
    // status -> fund (show all)
    // this.selectedFund = null;
    this.homePivot = 1;
    this.selectedDemo = null;
    this.viewMode = 'Status';
    this.uncheckAllCheckboxes();
  }
}

export default selectedFundsWatch;
