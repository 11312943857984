import { api } from '../api/stagingApi.js';

export async function checkFileYear({ idProject, year, enabled }) {
  try {
    const response = await api.post(`/files/changeFileCSV`, {
      idProject,
      year,
      enabled,
    });
    return response.data;
  } catch (error) {
    return {
      error: true,
      message: 'An error has occurred',
      details: '',
    };
  }
}

export async function useInsertDataOfExcel(formData) {
  try {
    const response = await api.post(`/files/insertExcelToMongo`, formData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  } 
}

export async function deleteFileFromList({ year, company, project }) {
  try {
    const response = await api.post(`/files/deleteExcelMongo`, {
      idProcess: project,
      idCompany: company,
      year,
    });
    return response.data;
  } catch (error) {
    return {
      error: true,
      message: 'An error has occurred',
      details: '',
    };
  }
}

export async function getCurrentDashboardsYears(idProject) {
  try {
    const response = await api.get(`/files/getYearsExists?idProject=${idProject}`);
    return response;
  } catch (error) {
    return {
      error: true,
      message: 'An error has occurred',
      details: '',
    };
  } 
}

export async function fileOverwriteManager(formaData) {
  try {
    const response = await api.post(`/files/overwriteExcel`, formaData);
    const status = response.status;
    const deletedFileName = response.data.deletedFileName;

    return {
      status,
      deletedFileName,
    };
  } catch (error) {
    return {
      error: true,
      message: 'An error has occurred',
      details: '',
    };
  }
}

export async function downloadDashboard(idproject, data) {
  try {
    const response = await api.post(`/files/dowloadTableExcel`, {
      idproject,
      data
    }, {responseType: 'blob'});
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'table_export.xlsx'; 
    link.click();

    URL.revokeObjectURL(url);
    return response;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}
