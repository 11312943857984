<template>
  <div>
    <main id="projectdetails">
      <div id="nav">
      </div>
      <div v-if="hasFramework">
        <div class="clickbtn" @click="frameworkBtnClick"></div>
        <see-framework-btn :buttonClicked="frameworkBtnClicked" class="see-framework"
          :style="setZindex()"></see-framework-btn>
        <div class="btn-wrapper" v-if="frameworkBtnClicked"></div>
        <img src="../assets/FrameworkBtn.svg" class="framework-btn" v-if="frameworkBtnClicked" />
      </div>
      <div v-show="!preloader">
        <HeaderDetails :title="Title" :isDemo="watchDemo" />

        <section class="projectdetails-select-area-projectdetails">
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="d-flex align-items-center">
                <div class="d-block mr-1">
                  <h3 class="">{{ Select1 }}</h3>
                </div>
                <div class="d-block">
                  <v-select :options="DataStatusArray" :reduce="(op) => op.label" v-model="selectedStatus"
                    :searchable="true" id="selectedStatus.label" class="project-select" placeholder="Show all"
                    :disabled="statusSelectboxDisabled">
                    <template v-slot:selected-option="option">
                      {{ clearTextOptionLabel(option.label) }}
                    </template>

                    <template slot="option" slot-scope="option">
                      {{ clearTextOptionLabel(option.label) }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="d-flex align-items-center">
                <div class="d-block mr-1">
                  <h3 class="">{{ Select2 }}</h3>
                </div>
                <div lass="d-block">
                  <!-- label="name"  -->

                  <v-select :options="fundsArray" placeholder="Show all" v-model="selectedFund" :reduce="op => op.name"
                    label="name" id="disabled-wrapper" :searchable="false" :disabled="fundSelectboxDisabled"
                    class="project-select">
                    <template v-slot:option="{ name }">
                      {{ name }}
                    </template>

                    <span slot="no-options">
                      <h3 class="no-option-message">
                        No more available options
                      </h3>
                    </span>
                  </v-select>


                </div>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="d-flex align-items-center">
                <div class="d-block mr-1">
                  <h3 class="">{{ Select3 }}</h3>
                </div>
                <div lass="d-block ">
                  <!-- label="name" need to do this in order to select: option.name -->
                  <v-select :options="this.demographics" placeholder="Select option" v-model="selectedDemo"
                    :reduce="demographics => demographics.name" label="name" id="disabled-wrapper" :searchable="false"
                    :disabled="demoSelectboxDisabled" class="project-select">
                    <template v-slot:option="{ name }">
                      {{ name }}
                    </template>

                    <span slot="no-options">
                      <h3 class="no-option-message">
                        No more available options
                      </h3>
                    </span>
                  </v-select>

                  <b-tooltip v-if="demoSelectboxDisabled" target="disabled-wrapper"
                    title="Please select an option from both drop-down 1 (status) and drop down 2 (fund) to activate the drop-down 3 (demographics) selection."></b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PeopleEmployment v-show="!preloader && !isLoadingCalcu" :statusLabel="Select1"
          :status="status" :totalTenants="totalTenants"
          :totalDifferenceTenantsByYears="totalDifferenceTenantsByYears" :unitMeasurement="unitMeasurement"
          :selectedStatus="selectedStatus" :selectedFund="selectedFund" :selectedDemo="selectedDemo"
          :growthRate="growthRate" :growthRatetext="growthRatetext" :displayDataUpTo="displayingDataUpTo"
          :EselectedYear="selectedYear" :dashboardNeedsPivotX="dashboardNeedsPivotX" @check="checkYear($event)" 
          :dropdownTimePeriodPercentageDifference="dropdownTimePeriodPercentageDifference"
          :numberDifferenceUnitMeasurement="numberDifferenceUnitMeasurement"
        />
        <div v-if="isLoadingCalcu" class="loading-container">
          <label v-if="dashboardNeedsPivotX" class="label-text">You need to save the dashboard configuration to
            start processing the data.</label>
          <a v-if="dashboardNeedsPivotX" :href="`/app/#/editbenchmetric/${impactId}`"
            class="dashboard-message-link">Click here to edit</a>
        </div>
        <section id="chart-areap" v-else>          <row :gutter="12" class="chart-main">
            <column :lg="8.76" class="line-chart-area">
              <project-chart :showGroupChartData="showGroupChartData" :linechartShow="linechartShow"
                :stackedChartShow="stackedChartShow" :ChartOptions="options" :yAxesValue="yAxes" :chartData="chartData"
                :stackedBarchartOption="stackedBarchartOption" :stackedBarChartData="stackedBarChartData" />
              <column :lg="4" :xs="12" class="year-select-box">
                <h3>
                  {{ getLavel().name }}
                </h3>
              </column>
            </column>
            <!-- Status summary start-->
            <!-- DemographicCheckbox -->
            <column column :lg="4" class="summary-area" v-if="stackedChartShow && viewMode === 'Demo'">
              <DemoCheckbox :status="status" :summaryBoxData="summaryBoxData" :checkedItems="checkedItems"
                @check="checkOptions($event)" />
            </column>

            <!--  Select status Todos los valores menos AllStatus -->
            <!-- StackedBarCheckbox -->
            <column :lg="3" class="summary-grid-area"
              v-if="stackedChartShow && viewMode === 'Status'">
              <StackCheckbox :status="status" :summaryBoxData="summaryBoxData" :checkedItems="checkedItems"
                @check="checkOptions($event)" />
            </column>

            <!-- BarCheckbox -->
            <column :lg="3.24" class="summary-area" v-if="viewMode === 'All' ||
              (selectedStatus === null && stackedChartShow === false) ||
              (linechartShow === true && viewMode === 'Fund')
              ">
              <BarCheckbox :status="status" :itemArr="checkedItems" @check="checkOptions($event)"
                :linechartShow="linechartShow" :summaryBoxData="summaryBoxData" :barChartData="barChartData"
                :barchartOption="barchartOption" />
              </column>
              <div class="d-flex w-100 justify-content-end">
                  <div class="d-flex justify-content-end mb-2 w-25">
                    <SaveDashboardButton :titleBtn="'Export table'" :saveDashboard="saveDashboard" class="" />
                  </div>
              </div>
          </row>

          <TableEmployment :status="tableStatus.active" :tableData="tableData" :checkedItems="checkedItems"
            :headerTitles="headerTitles" v-if="linechartShow === true && viewMode !== 'Fund'" />
            <!-- {{ tableData }} -->
          <TableEmployment :status="tableStatus.active" :tableData="tableDataByStatus" :checkedItems="checkedItems"
            :headerTitles="headerTitles" v-if="(stackedChartShow === true && viewMode === 'Status') ||
              (linechartShow === true && viewMode === 'Fund')
              " />

          <table-for-topic :status="tableStatus" :tableData="TopicTableData" :checkedItems="checkedItems"
            :headerTitles="headerTitles" v-if="viewMode === 'Demo'"></table-for-topic>
        </section>
      </div>
      <PreLoader v-if="preloader" :TopicTableData="TopicTableData" :tableData="tableData"
        :tableDataCalculos="tableDataCalculos" :eselectedYear="selectedYear" :calculationTable="calculationTable"
        :tableDataByStatus="tableDataByStatus" :stackedChartShow="stackedChartShow" :viewMode="viewMode"
        :linechartShow="linechartShow" />
    </main>
  </div>
</template>

<script>
import { clearText } from "../utils/textUtilities";
import { getImpact } from "../services/impact";
import { getMonths } from "../data/data-handler";
import { handleAllCase, handleStatusCase, handleFundCase, handleDemoCase } from "../helpers/projectDetails/updateConditionalRendering/conditionalRenderHelpers";
import { handleAllCaseDashboard } from "../helpers/projectDetails/casesDashboards/handleAllCase";
import { handleDemographicsCaseDashboard } from "../helpers/projectDetails/casesDashboards/handleDemographicsCase";
import { handleFundsCaseDashboard } from "../helpers/projectDetails/casesDashboards/handleFundCase";
import { handleStatusCaseDashboard } from "../helpers/projectDetails/casesDashboards/handleStatusCase";
import { store } from "../store/store";

import BarCheckbox from "../components/Chart/Checkboxes/BarCheckbox.vue";
import DemoCheckbox from "../components/Chart/Checkboxes/DemoCheckbox.vue";
import HeaderDetails from '../components/project/HeaderDetails.vue'
import PeopleEmployment from "../components/Chart/PeopleEmployment.vue";
import PreLoader from "../components/Chart/PreLoader.vue";
import ProjectChart from "../components/ProjectChart.vue";
import SeeFrameworkBtn from "../components/SeeFrameworkBtn.vue";
import selectedDemographicsWatch from "../helpers/projectDetails/watch/selectedDemographics";
import selectedFundsWatch from "../helpers/projectDetails/watch/selectedFunds";
import selectedStatusWatch from "../helpers/projectDetails/watch/selectedStatus";
import StackCheckbox from "../components/Chart/Checkboxes/StackCheckbox.vue";
import TableEmployment from "../components/TableEmployment.vue";
import TableForTopic from "../components/TableforTopic.vue";
import { updateColorsInView } from "../helpers/projectDetails/colors/updateColors";
import { barchartOption, options, stackedBarchartOption } from "../helpers/chart/chartOptions";
import SaveDashboardButton from "../components/EditBenchMetric/SaveDashboardButton.vue";
import { downloadDashboard } from "../services/files";
// import PopUpCardModal from "../components/shared/PopUpCardModal.vue";


export default {
  components: {
    BarCheckbox,
    DemoCheckbox,
    HeaderDetails,
    PeopleEmployment,
    PreLoader,
    ProjectChart,
    SeeFrameworkBtn,
    StackCheckbox,
    TableEmployment,
    TableForTopic,
    SaveDashboardButton
},
  data() {
    return {
      isLoadingCalcu: true,
      hasFramework: false,
      frameworkBtnClicked: false,
      Title: "",
      viewMode: "All",
      unitMeasurement: "",
      headerTitles: {},
      selectedStatus: "",
      selectedFund: null,
      selectedDemo: null,
      selectedYear: '',
      chartData: {},
      xAxes: "",
      yAxes: "",
      linechartShow: true,
      stackedChartShow: false,
      fundSelectboxDisabled: false,
      demoSelectboxDisabled: true,
      statusSelectboxDisabled: false,
      barChartData: {},
      stackedBarChartData: {},
      tableData: [],
      tableDataByStatus: [],
      TopicTableData: [],
      summaryBoxData: [],
      DataStatus: [],
      funds: [],
      demographics: [],
      status: "",
      checkedItems: [],
      totalTenants: 0,
      totalDifferenceTenantsByYears: 0,
      growthRate: "",
      fundPlaceholder: "Select status to activate",
      colorIndex: [],
      homePivot: 0,
      impactId: "",
      preloader: true,
      watchDemo: false,
      groups: {},
      growthRatetext: "",
      displayingDataUpTo: [],
      calculationTable: {},
      showGroupChartData: false,
      ChartOptions: "",
      tableStatus: "demo",
      DataStatusArray: [],
      fundsArray: [],
      tableDataCalculos: {},
      tableDataCalculo: {},
      Select1: "",
      Select2: "",
      Select3: "",
      pivotX: "",
      dashboardNeedsPivotX: false,
      isNumeric: true,
      isSelectedCheckboxInViewAll: false,
      modals: [
        {
          title: 'Save dashboard',
          text: '',
          isOk: true,
        },
      ],
      tenants: {},
      prevYearTenants: {},
      totalCurrTenants: [],
      totalPrevTenants: [],
      SchartData: {},
      tempo1: [],
      tempo2: [],
      tableTenants: {},
      prevtableTenants: {},
      previousTenants: {},
      dropdownTimePeriodPercentageDifference: "",
      numberDifferenceUnitMeasurement: "",
      secondDropdownOption: null,
    };
  },
  methods: {
    async saveDashboard() {
      try {
        const resp = await downloadDashboard(this.impactId, this.summaryBoxData);
        console.log(resp);
      } catch (error) {
        console.log(error);
      }
    },
    clearTextOptionLabel(text) {
      return clearText(text);
    },
    getYearsDataPage(lastDatapages) {
      const lastDatapage = JSON.parse(JSON.stringify(lastDatapages));
      if (lastDatapage.length > 0) {
        const displayYearOrdered = lastDatapage.sort((a, b) => {
          let year_a = parseInt(a.year),
            year_b = parseInt(b.year);
          if (year_a < year_b) {
            return 1;
          }
          if (year_a > year_b) {
            return -1;
          }
          return 0;
        });

        this.selectedYear = displayYearOrdered[0].year || "2021";
      }
    },
    getLavel() {
      if (this.selectedStatus && this.selectedFund) {
        this.tableStatus = this.xAxes || this.headerTitles?.tableColumn1 || this.pivotX;
        return this.xAxes || this.pivotX;
      }
      // TODO: PENDING
      // if (this.selectedStatus) {
      //   this.tableStatus = "Funds";
      //   return "Funds";
      // }
      if (this.selectedFund) {
        this.tableStatus = this.xAxes || this.headerTitles?.tableColumn1 || this.pivotX;
        return this.xAxes || this.pivotX;
      }
      this.tableStatus = this.xAxes || this.headerTitles?.tableColumn1 || this.pivotX;
      return this.xAxes || this.pivotX;
    },
    truncateString(str, num) {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + ".";
    },
    frameworkBtnClick() {
      this.frameworkBtnClicked = true;
    },
    setZindex() {
      if (this.frameworkBtnClicked) {
        return "z-index: 10;";
      }
    },
    //funcionupdateData
    async updateData() {
      this.updateConditionalRendering();

      switch (this.viewMode) {
        case "All":
          try {
            handleAllCaseDashboard.call(this);
          } catch (error) {
            console.log(error);
          }
          break;
        case "Status":
          try {
            handleStatusCaseDashboard.call(this);
          } catch (error) {
            console.log(error);
          }
          break;
        case "Fund":
          handleFundsCaseDashboard.call(this)
          break;
        case "Demo":
          try {
            handleDemographicsCaseDashboard.call(this);
          } catch (error) {
            console.log(error);
          }
          break;
      }
    },
    updateConditionalRendering() {
      // console.log(this.viewMode, "this.homePivot", this.homePivot);
      switch (this.viewMode) {
        case "All":
          handleAllCase.call(this);
          break;
        case "Status":
          handleStatusCase.call(this);
          break;
        case "Fund":
          handleFundCase.call(this);
          break;
        case "Demo":
          handleDemoCase.call(this);
          break;
      }
    },
    uncheckAllCheckboxes() {
      this.checkedItems = [];
    },
    filterChartData(chartData, filter) {
      if (filter.length !== 0) {
        const filtered = chartData.datasets.filter((el) => {
          return filter.indexOf(el.babelExtra) !== -1;
        });
        chartData.datasets = filtered;
      }
      return chartData;
    },
    filterChartDataDos(chartData, filter) {
      if (filter.length !== 0) {
        const checked = this.checkedItems;
        const colorIndex = [];
        checked.forEach((el) => {
          colorIndex.push(chartData.labels.indexOf(el));
        });
        this.colorIndex = colorIndex;
        const dataIndex = [...Array(chartData.labels.length).keys()].filter(
          (el) => colorIndex.includes(el) === false
        );
        dataIndex.forEach((el) => {
          chartData.datasets[0].data.splice(el, 1, " ");
        });
      }
      return chartData;
    },
    filterChartDataDemo(chartData, filter) {
      if (filter.length !== 0) {
        const filtered = chartData.datasets.filter((el) => {
          return filter.indexOf(el.babelExtra) !== -1;
        });
        chartData.datasets = filtered;
      }
      return chartData;
    },
    filterTableDemo(chartData, filter) {
      var tem = [];
      chartData.map((table) => {
        filter.datasets.map((data) => {
          if (data.babelExtra === table.name) {
            tem.push(table);
          }
        });
      });
      return tem;
    },
    updateColors(view, colorScheme) {
      updateColorsInView.call(this, view, colorScheme)
    },

    checkOptions(checkStatus) {
      if (!this.checkedItems.includes(checkStatus)) {
        this.checkedItems.push(checkStatus);
      } else {
        this.checkedItems = this.checkedItems.filter((e) => e !== checkStatus);
      }
    },
    checkYear(yearSelected) {
      this.selectedYear = yearSelected;
    },
  },

  async mounted() {
    this.watchDemo = store.state.demoView;
    if (this.$route.params.id == "777cbb777e77bb7e7c7b77e7") {
      this.impactId = "650a9be87021240011f9edb1";
    } else {
      this.impactId = this.$route.params.id;
    }

    if (this.watchDemo) {
      this.options.scales = {
        xAxes: [
          {
            gridLines: {
              color: "#ffffff",
            },
            ticks: {
              callback: function (value, index) {
                const months = getMonths();
                value = months[index];
                return value;
              },
            },
          },
        ],
      };
    }

    const res_data = await getImpact(this.impactId);
    if (res_data.error === false) {
      const lastDatapage = res_data.impact;
      this.pivotX = {
        active: false,
        name: lastDatapage.pivotX ? lastDatapage.pivotX : 'Length of stay In months',
        order: 0
      };
      this.secondDropdownOption = lastDatapage.Fund;
      this.fundPlaceholder = lastDatapage.fundPlaceholder;
      this.unitMeasurement = lastDatapage.unitMeasurement;
      this.dropdownTimePeriodPercentageDifference = lastDatapage.dropdownTimePeriodPercentageDifference;
      this.numberDifferenceUnitMeasurement = lastDatapage.numberDifferenceUnitMeasurement;
      this.headerTitles.column1 = lastDatapage.tableColumn1 || (lastDatapage.pivotX ? lastDatapage.pivotX : 'Length of stay In months');
      this.headerTitles.column10 = (lastDatapage.tableColumn10 && lastDatapage.tableColumn10.trim().length > 0) ? lastDatapage.tableColumn10 : "Total tenancies";
      this.headerTitles.column11 = (lastDatapage.tableColumn11 && lastDatapage.tableColumn11.trim().length > 0) ? lastDatapage.tableColumn11 : "Positive move on";
      this.headerTitles.column12 = (lastDatapage.tableColumn12 && lastDatapage.tableColumn12.trim().length > 0) ? lastDatapage.tableColumn12 : "Move on in avg months";

      this.growthRatetext = lastDatapage.growthRatetext;
      this.xAxes = lastDatapage.XAxes;
      this.yAxes = lastDatapage.YAxes;

      this.Title = lastDatapage.Title ? lastDatapage.Title : lastDatapage.pivotOrigin;
      this.selectedName = lastDatapage.pivotOrigin ? lastDatapage.pivotOrigin : lastDatapage.Title;
      this.DataStatus = lastDatapage.Status;
      this.DataStatusArray = lastDatapage.Status.map((status) => {
        return {
          label: status,
          code: status,
        };
      });
      this.DataStatusArray.unshift({
        label: "Show all",
        code: null,
      });
      this.funds = lastDatapage.funds;

      let DataFundsList = lastDatapage.funds.filter((fund) => fund.status != false && fund.name !== 'No information');

      this.fundsArray = DataFundsList
      this.fundsArray.unshift({ active: true, name: "Show all", order: null });

      // demographics options
      const filteredDemographics = lastDatapage.demographics.filter(demo => demo.status === true && demo.name !== 'No information' && demo.name !== 'Age at survey date');
      // Agregar la opción de "Select option" al principio
      filteredDemographics.unshift({ active: true, name: "Select option", order: null });
      this.demographics = filteredDemographics;


      // this.Theme = lastDatapage.Theme;
      this.Select1 = lastDatapage.select1 || 'status'
      this.Select2 = lastDatapage.select2 || 'fund'
      this.Select3 = lastDatapage.select3 || 'demographic'
      this.displayingDataUpTo = lastDatapage.yearsAvailable || [];
      // Sort from highest to lowest according to the year.
      this.displayingDataUpTo.sort((a, b) => b.year - a.year);

      this.getYearsDataPage(lastDatapage.yearsAvailable);

      // this.updateData();
      this.preloader = false;
    }
  },

  watch: {
    checkedItems() {
      this.updateData();
    },
    selectedYear() {
      this.uncheckAllCheckboxes();
    },
    selectedStatus() {
      selectedStatusWatch.call(this);
    },
    selectedFund() {
      selectedFundsWatch.call(this);
    },
    selectedDemo() {
      selectedDemographicsWatch.call(this);
    }
  },
  computed: {
    options() {
      return options;
    },
    stackedBarchartOption() {
      return stackedBarchartOption;
    },
    barchartOption() {
      return barchartOption;
    }
  }
};
</script>

<!-- FIXME: apply scoped styles -->
<!-- <style scoped src="@/assets/styles/views/ProjectDetails.css"></style> -->
<style src="@/assets/styles/views/ProjectDetails.css"></style>
